.main {
    background-color: #fff;
    border-radius: 7px;
    padding: 2rem;
    width: 69%;
}

.main .header {
    margin-bottom: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header p {
    color: var(--accent-color);
    cursor: pointer;
}

.header p:hover {
    text-decoration: underline;
}