.board {
    position: relative;
    width: calc(25% - 6px);
    margin-bottom: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    height: 10rem;
    cursor: pointer;
}

.board a,
.board a:hover {
    text-decoration: none !important;
}

.board p {
    color: #fff;
    font-weight: 600;
    font-size: 1.4rem;
}

.board .settings {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.8rem;
    border: none;
    font-size: 1.2rem;
    color: #fff;
    background-color: transparent;
    transition: 0.2s transform ease-in-out;
    cursor: pointer;
}

.board .settings:hover {
    transform: rotate(28deg);
}

@media only screen and (min-width: 268px) and (max-width: 429px) {
    .board {
        width: 100%;
        margin-right: 0;
    }
}

@media only screen and (min-width: 430px) and (max-width: 799px) {
    .board {
        width: calc(50% - 4px);
    }
}

@media only screen and (min-width: 430px) and (max-width: 749px) {
    .board:nth-child(2n) {
        margin-right: 0;
    }
}

@media only screen and (min-width: 750px) and (max-width: 980px) {
    .board {
        width: calc(33% - 3px);
    }
}

@media only screen and (min-width: 750px) and (max-width: 980px) {
    .board:nth-child(3n) {
        margin-right: 0;
    }
}

@media only screen and (min-width: 981px) {
    .board:nth-child(4n) {
        margin-right: 0;
    }
}

.hover {
    display: block;
    border-radius: 5px;
    position: absolute;
    inset: 0;
    background-color: #00000026;
}

.delete {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    font-size: 1.1rem;
    background-color: transparent;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.delete:hover {
    background-color: #ffffff26;
}