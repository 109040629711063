.main {
    margin: 0.4rem 0 1rem 0;
    padding: 0.2rem 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 95%;
    max-width: 600px;
    box-shadow: var(--box-shadow);
}

.main input.bar {
    background-color: #fff !important;
    font-size: 1.2rem !important;
    padding-left: 1rem !important;
    height: 100%;
    margin: 0 !important;
    border: none !important;
}

.main input.bar:hover,
.main input.bar:focus {
    background-color: #fff !important;
    box-shadow: none !important;
}

.main button {
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.main button i {
    font-size: 1.1rem;
}