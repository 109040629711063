.overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 300;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 95%;
    max-width: 480px;
    background-color: #fff;
    padding: 1.2rem 1.4rem;
    border-radius: 7px;
    transform: translate(-50%, -50%);
    z-index: 301;
    box-shadow: 0 4px 10px rgb(0 0 0 / 21%), 0 1px 0 rgb(0 0 0 / 5%);
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal h2 {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
}

.modal ul {
    width: 100%;
    margin: 0.6rem 0 0.6rem 1.2rem;
    font-size: 1.1rem;
}

.modal li {
    margin-bottom: 0.1rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem;
}

.buttons button {
    width: 100px !important;
    margin-left: 7px;
    background-color: var(--accent-color);
    color: #fff;
}

.buttons button.cancel {
    max-width: 80px !important;
    background-color: #eeeeee;
    color: #000000;
}

.buttons button.cancel:hover {
    background-color: #dddddd;
}