.main {
    display: flex;
    background-color: #fafffd;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    width: 100%;
}

.main h1 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

.main h1 ~ p {
    font-size: 1.1rem;
    text-align: center;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 100%;
    max-width: 1280px;
    margin-top: 2rem;
    padding: 0 15px;
}

.feature {
    width: 32%;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
}

.feature:hover {
    transform: scale(1.03);
}

.feature i {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 1.4rem;
    background: linear-gradient(to bottom, var(--accent-color), rgba(60 145 230 / 49%));
    height: 3rem;
    width: 3rem;
    color: #ffffff;
}

.feature h3 {
    font-size: 1.4rem;
    margin: 0.6rem 0;
}

@media screen and (max-width: 760px) {
    .features {
        flex-direction: column;
    }

    .feature {
        width: 100%;
        margin-bottom: 1rem;
    }
}