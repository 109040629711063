.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #CFCFCF;
    border-radius: 7px;
    padding: 1.4rem 2rem;
    flex-grow: 1;
    margin: 0.4rem 0;
}

.card h2 {
    margin: 0;
}

.card span {
    display: block;
    margin-top: 0.4rem;
}

.card button {
    background-color: var(--accent-color);
    color: #fff;
    font-weight: 600;
    border: 1px solid #CFCFCF;
    border-radius: 7px;
    padding: 0 0.8rem;
    height: 40px;
    cursor: pointer;
}