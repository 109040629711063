:root {
    --accent-color: #3c91e6;
    --text-color: #342e37;
    --background-color: #FAFBFC;
    --success-color: #1d7874;
    --error-color: #da4167;
    --font-family: 'Work Sans', sans-serif;
    --box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: var(--text-color);
    font-family: 'Work Sans', sans-serif;
    background-color: var(--background-color);
}

.textarea-empty {
    border: 5px solid var(--error-color);
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]),
select {
    font-family: 'Work Sans', sans-serif;
    background: #fafafa;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #cfcfcf;
    padding: 10px 12px 11px;
    font-size: 1rem;
    line-height: 17px;
    letter-spacing: -.006em;
    margin-bottom: 12px;
    resize: none;
    outline: none;
    transition: 0.2s ease-in-out;
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]):hover,
select:hover {
    background-color: #f1f1f1;
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]):focus,
select:focus {
    background-color: #f1f1f1;
    box-shadow: 0 0 0 0.15rem #3c91e67e;
}

input.error-input {
    border: 1px solid var(--error-color) !important;
    box-shadow: none !important;
}

.input-feedback {
    font-size: 0.9rem;
    margin-top: -5px;
    margin-bottom: 12px;
    text-align: left;
    width: 100%;
}

.input-feedback.error {
    color: var(--error-color) !important;
}

a {
    text-decoration: none;
    color: var(--accent-color);
    transition: 0.2s ease-in-out;
}

a:hover {
    text-decoration: underline;
}

.simple-container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
}

button:disabled {
    cursor: not-allowed;
}

button.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    background: var(--accent-color);
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s background-color ease-in-out;
    outline: none;
    border: none;
}

button.button:hover {
    background-color: #308ce9;
}

button.button:disabled {
    background-color: #2686e6;
    cursor: not-allowed;
}

button.button i {
    margin-right: 7px;
}

button.button i.fa-spinner-third {
    margin-right: 0;
    font-size: 18px;
    animation: 0.7s spinner infinite linear;
}

.google-auth i {
    margin-left: 7px;
    animation: 0.7s spinner infinite linear;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

button.home-btn {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 1rem 4rem;
    font-size: 1.2rem;
    font-weight: 600 !important;
    box-shadow: 5px 5px 4px rgba(60 145 230 / 49%);
    font-family: 'Work Sans', sans-serif !important;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

button.home-btn:hover {
    font-weight: 600 !important;
    transform: scale(1.05);
}

button.home-btn a {
    color: #fff;
    text-decoration: none;
}

button.home-btn a:hover {
    color: #fff;
    text-decoration: none;
}

body:not(.homepage) .crisp-client {
    display: none;
}