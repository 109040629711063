.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 25px;
    width: 100%;
}

.main h3 {
    font-size: 2.2rem;
    text-align: center;
}

.main p {
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem 0;
}