.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6rem 0 3rem 0;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    padding: 0 1rem;
}

.container h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    color: #5e6c84;
}

.boards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}