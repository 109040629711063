.footer {
    background-color: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    max-width: 1280px;
}

.footer a, .footer p {
    color: #ffffff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}