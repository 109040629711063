.loading {
    position: fixed;
    inset: 0;
    background-color: var(--background-color);
    z-index: 100000;
}

.loading i {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: #333333;
    animation: spinner 1s infinite linear;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}