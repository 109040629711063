.overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
}

.main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 1.6rem 2rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
}

.header button {
    display: flex;
    align-items: center;
    border-radius: 7px;
    border: none;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    transition: background-color 0.2s ease-in-out;
    background-color: #eeeeee !important;
    color: #000000 !important;
}

.header button:hover {
    background-color: #dddddd !important;
}

.users {
    height: calc(100% - 3.2rem);
    overflow-y: scroll;
}