.card {
    margin: 0.4rem 0;
    padding: 1.4rem;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 95%;
    max-width: 600px;
    min-height: 60px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.card:hover {
    background-color: #fcfcfc;
}

.card p {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.3;
    /* margin: 0.3rem 0; */
    word-wrap: break-word;
    color: var(--text-color);
}

.card p.space {
    margin: 0.5rem 0;
}

.shrink {
    transform: scale(0.98);
}

.checkbox {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.checkbox.active {
    background-color: #0a9396;
    border: 2px solid #0a9396;
}

.checkbox i {
    font-size: 11px;
    color: #fff;
}