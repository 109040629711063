.card {
    border: 1px solid #CFCFCF;
    border-radius: 7px;
    padding: 0.8rem;
    width: 100%;
    margin: 0.4rem 0;
}

.card .title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
}

.card .number {
    font-size: 2rem;
    font-weight: bold;
}