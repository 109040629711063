.alert {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    left: 25px;
    background-color: #151515;
    color: white;
    width: 90%;
    max-width: 300px;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    animation: 3s alertAnimation;
    z-index: 1000000;
}

@keyframes alertAnimation {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.alert p {
    font-size: 0.9rem;
    text-align: center;
}