.header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 100%;
    background-color: var(--accent-color);
    color: #fff;
    z-index: 3;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    padding: 0 1rem;
}

.container h1 {
    font-weight: 600;
}

.container h1 a {
    color: #fff;
    text-decoration: none !important;
}

.container .button {
    background-color: transparent;
    border-radius: 5px;
    height: 2.8rem;
    color: #fff;
    cursor: pointer;
}

.account {
    position: relative;
}

button.account {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 100%;
}

button.account img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 100%;
}

.overlay {
    position: absolute;
    inset: 0;
    z-index: 90;
}

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 3.6rem;
    right: 0;
    padding-bottom: 0.6rem;
    box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
    z-index: 91;
}

.dropdown .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.2rem;
}

.dropdown .profile .avatar {
    margin-right: 0.6rem;
}

.dropdown .profile .avatar img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 100%;
}

.dropdown .name p {
    color: var(--text-color);
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown .name span {
    color: #b3bac5;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown button {
    display: block;
    width: 100%;
    padding: 0.6rem;
    padding-left: 1.2rem;
    font-size: 0.95rem;
    text-align: left;
    color: var(--text-color);
    text-decoration: none;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dropdown button:hover {
    background-color: rgba(9, 30, 66, 0.04);
    text-decoration: none;
}

.dropdown a {
    width: 100%;
    text-decoration: none !important;
}

.divider {
    display: block;
    height: 1px;
    width: 95%;
    background-color: #eee;
}

.bannerText {
    font-weight: 500;
    color: #fff;
}

.bannerButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.bannerButton {
    background-color: #fff;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.bannerButton:hover {
    background-color: rgba(255, 255, 255, 0.75);
}
