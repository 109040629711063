.newMember {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.newMember input {
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #ccc;
    border-radius: 100px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

.newMember button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 14px;
    border: 2px solid transparent;
    background-color: transparent;
    border-radius: 100px;
    font-size: 16px;
    color: var(--success-color);
    font-family: 'Roboto', sans-serif;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.newMember button:hover {
    background-color: #00000010;
}

.members {
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
}

.user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.removeBtn {
    background-color: transparent;
    border: none;
    font-size: 1.1rem;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: var(--error-color);
}

.removeBtn:hover {
    background-color: #00000010;
}

.profile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    padding: 0.5rem 0;
}

.profile .avatar {
    margin-right: 0.75rem;
}

.profile .avatar img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 100%;
}