.toggle {
    background-color: var(--accent-color);
    border-radius: 100%;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    height: 3.6rem;
    width: 3.6rem;
    outline: none;
    position: fixed;
    bottom: 2rem;
    z-index: 11;
    transition: all 0.1s ease-in-out;
}

.tilted {
    transform: rotate(45deg);
}

.overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.modal {
    position: fixed;
    bottom: 7rem;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    max-width: 600px;
    background-color: #fff;
    padding: 1.2rem 1.4rem;
    border-radius: 7px;
    z-index: 4;
    box-shadow: 0 4px 10px rgb(0 0 0 / 21%), 0 1px 0 rgb(0 0 0 / 5%);
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal h2 {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem;
}

.buttons button {
    width: 100px !important;
    margin-left: 7px;
    background-color: var(--accent-color);
    color: #fff;
}

.buttons button.cancel {
    max-width: 80px;
    background-color: #eeeeee;
    color: #000000;
}

.buttons button.cancel:hover {
    background-color: #dddddd;
}

.content textarea {
    width: 100%;
    height: 100%;
    min-height: 220px;
    border-radius: 5px;
    padding: 0.6rem;
    font-size: 1.2rem;
    line-height: 1.4;
    resize: vertical;
    font-family: 'Work Sans', sans-serif;
    resize: none;
    outline: none;
    transition: 0.2s ease-in-out;
    border: 1px solid #cfcfcf;
}

.content textarea:hover {
    background-color: #fafafa;
}

.content textarea:focus {
    background-color: #fafafa;
    box-shadow: 0 0 0 0.15rem #3c91e67e;
}

.error {
    box-shadow: 0 0 0 0.15rem #d4224ea4 !important;
}