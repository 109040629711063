.main,
.cards {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 1.4rem 0;
}

.main {
    margin-top: 5rem;
}

.cards {
    margin-top: 0.4rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    max-width: 600px;
    height: 100%;
    margin: 0.8rem 0;
}

.header h2 {
    font-size: 2rem;
}

.header .cogBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem;
    width: 2.2rem;
    font-size: 1.4rem;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.header .cogBtn:hover {
    background-color: #00000010;
}

.noCards {
    margin: 0.4rem 0;
    padding: 2.6rem 1.4rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 95%;
    max-width: 600px;
    min-height: 60px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-in-out;
}

.noCards p {
    font-size: 1.2rem;
}

.controls {
    display: flex;
    flex-direction: column;
    position: relative;
}

.dropdown {
    position: absolute;
    bottom: -160px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    transition: all 0.1s ease-in-out;
    z-index: 1;
    padding: 0.375rem 0;
}

.dropdown button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-color);
    border: none;
    outline: none;
    font-size: 0.95rem;
    padding: 0.6rem;
    padding-left: 1.2rem;
    padding-right: 2.4rem;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
}

.dropdown button:hover {
    background-color: #00000009;
}

.controls button:nth-of-type(1) {
    margin-right: 0.2rem;
}

.loadingDiv {
    background-color: #f1f1f1;
    color: transparent;
    border-radius: 7px;
    animation: colorchange 0.8s linear infinite alternate;
    width: 95%;
    margin-bottom: 1rem;
    max-width: 600px;
}

@keyframes colorchange {
    0% {
        background-color: hsl(200, 20%, 90%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.scroll {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    margin-bottom: 7rem;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.selectedCardsModal {
    background-color: #fff;
    box-shadow: var(--box-shadow);
    border: 1px solid #eee;
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    border-radius: 7px;
    padding: 1rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.selectedCardsModal p {
    font-size: 1.175rem;
}

.selectedCardsModal .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.8rem;
    margin-right: 7px;
}

.selectedCardsModal .buttons button {
    width: 80px !important;
    height: 35px;
    background-color: var(--accent-color);
    color: #fff;
}

.selectedCardsModal .buttons button.cancel {
    background-color: #eeeeee;
    color: #000000;
}

.selectedCardsModal .buttons button.cancel:hover {
    background-color: #dddddd;
}

@media screen and (max-width: 600px) {
    .selectedCardsModal {
        bottom: 1rem;
        right: 0.6rem;
    }
}